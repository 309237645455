import { createGlobalStyle } from "styled-components";

const Variable = createGlobalStyle`

:root {
// Color Variable
--theme--color: #F68C3D;
--white--color: #ffffff;
--black--color: #000000;

--success--color: #089B55;
--danger--color: #D33520;
--warning--color: #E5B64B;
--info--color: #448FB8;
}
` ;

export default Variable