import { createGlobalStyle } from "styled-components";

export const CommonModal = createGlobalStyle`
    
    .common-modal{
        .ant-modal-content{
            .ant-modal-close{
                position: absolute;
                right: -10px;
                top: -20px;
                background-color: rgb(246, 140, 61);
                width: 35px;
                height: 35px;
                border-radius: 100%;
                color: #fff;
            }
        }
    }

`;