import React from 'react'
import { CommonTab } from './tab'
import { CommonFormFiled } from './formfiled'
import { CommonTable } from './table'
import { CommonModal } from './modal'

const ComponentsStyle = () => {

  return (
    <>
      <CommonTab />
      <CommonFormFiled />
      <CommonTable />
      <CommonModal />
    </>
  )
}

export default ComponentsStyle