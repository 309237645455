
import styled from 'styled-components';
import { device } from '../../style/breakpoints';


export const Wrapper = styled.div`
        &.login{
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
        }
        .login-wrap{
            max-width:600px;
            width:100%;
            .login-form{
                background-color:var(--white--color);
                border-radius: 8px;
                padding:32px;
                border: 1px solid #c8ced3;
            }
            .title{
                font-size: 26px;
                color: #242424;
            }
            .desc{
                font-family: 'Inter';
                font-weight: 400;
                font-size: 14px;
                color: #6B7280;
                margin:16px 0 24px;
            }
            .f-btn{
                font-family: 'Inter';
                font-weight: 400;
                font-size: 16px;
                color: #FE7A18;
            }
            .horizontal-line{
                border-top: 1px solid rgb(230, 232, 240);
                padding-top: 24px;
                margin-top: 24px;
            }
            .ant-btn-primary{
                padding:8px 20px;
                font-size:14px;
                svg{
                    max-width:14px;
                }
            }
        }

        @media only screen and (${device.sm}){
            .login-wrap{
                .login-form{
                    padding:20px;
                }
            }
        }

`;