import { configureStore } from '@reduxjs/toolkit'
import reduxApiMiddleware from './Middleware'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import CmsSizeSlice from './CmsSlice'
import UserSlice from './UserSlice'
import OnboardingscreenSlice from './OnboardingscreenSlice'
import VenueProfileSlice from './VenueProfileSlice'
import CuisineManagement from './CuisineManagement'
import HeatSlice from './HeatManagement'
import RestaurantSlice from './RestaurantManagementSlice'
import PaymentManagementSlice from './PaymentManagementSlice'
import GoatGuideSllce from './GoatGuideSllce'
import NotificationSlice from './NotificationSlice'
import CreditSclice from './CreditSclice'
import AnalyticsSlice from './AnalyticsSlice'
import FranchiseSlice from './FranchiseSlice'
import SuggetionsSlice from './SuggestionsManagementSlice'

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        user: UserSlice,
        cms: CmsSizeSlice,
        onboardingscreens: OnboardingscreenSlice,
        VenueProfileSlice,
        CuisineManagement,
        HeatSlice,
        restaurant: RestaurantSlice,
        payment: PaymentManagementSlice,
        goatGuide: GoatGuideSllce,
        notification: NotificationSlice,
        credit: CreditSclice,
        analytic: AnalyticsSlice,
        franchise: FranchiseSlice,
        suggetions: SuggetionsSlice

    },
    middleware: [thunk, reduxApiMiddleware]
}) 