import { createSlice } from '@reduxjs/toolkit';
import { API_ADD_HEATINDEX, API_DELETE_HEAT, API_GET_HEAT, API_LIST_HEAT, API_UPDATE_HEAT } from '../constants';

const initialState = {
    isLoading: false,
    heatDetail: {},
    heatList: { data: [], recordsTotal: 0 },
}
export const ADDHeatIndex = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_HEATINDEX,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addHeat/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addHeat/fail',
            payload: {},
        }),
    },
})

export const AllHEATLIST = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_HEAT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'allHeatList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'allHeatList/fail',
            payload: {},
        }),
    },
})

export const UpdateHeatIndex = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_HEAT,
        method: 'PATCH',
        data: data,
        hideLoader: false
    },
})

export const GetHeatIndex = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_HEAT,
        method: 'POST',
        data: data,
        hideLoader: false
    },
})


export const DeleteHeatIndex = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_HEAT,
        method: 'DELETE',
        data: data,
        hideLoader: false
    },
})


const HeatSlice = createSlice({
    name: 'heatSlice',
    initialState: initialState,
    // reducers: {
    //     loaderChange: (state, action) => {
    //         state.isLoading = action.payload
    //     },
    // },
    extraReducers: (builder) => {
        builder.addCase('allHeatList/success', (state, action) => {
            state.recordsTotal = action.payload.data.recordsTotal
            state.heatList = action.payload.data.data
        })
    }

})


// export const { loaderChange } = VenueProfileSlice.actions
export default HeatSlice.reducer
