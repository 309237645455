import React from 'react'
import Variable from './variable'
import Globals from './col'
import Common from './common'
import Flex from './flex'
import Reset from './reset'
import Theme from './theme'
import ComponentsStyle from './components'

const GlobalStyle = () => {

  return (
    <>
      <Variable />
      <Globals />
      <Common />
      <Flex />
      <Reset />
      <ComponentsStyle />
      <Theme />
    </>
  )
}

export default GlobalStyle