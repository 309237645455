import React, { Suspense, useEffect } from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "../Redux/store";
import Routes from "./routes";
import GlobalStyle from '../style/global';
import ScrollToTop from '../components/common/scroll';
import Loader from "../components/common/loader";
import { setupAxios } from "../utils";
import { ErrorBoundary } from "../components/Error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "../utils/firebase";

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

const AppContainer = () => {
  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        // openNotification(payload, "top");
      })
  },)
  return (
    <ErrorBoundary>
      <ToastContainer />
      <Provider store={store}>
        <Suspense fallback={<Loader isSuspense />}>
          {/* <Loader> */}
          <BrowserRouter basename={PUBLIC_URL}>
            <ScrollToTop>
              <GlobalStyle />
              <Routes />
            </ScrollToTop>
          </BrowserRouter>
          {/* </Loader> */}
        </Suspense>
      </Provider>
    </ErrorBoundary>
  )
};

export default AppContainer;
