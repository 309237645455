import { createSlice } from '@reduxjs/toolkit';
import { API_ADD_CUISINE, API_ADD_FOODITEM, API_ADD_MEAL, API_CHANGE_SEQUENCE_MEAL, API_DELETE_CUISINE, API_DELETE_FOODITEM, API_DELETE_MEAL, API_DELETE_SUBITEMS, API_GET_CUISINE, API_GET_FOODITEM, API_GET_MEAL, API_GET_SUBITEMS, API_LIST_ADD_SUBITEMS, API_LIST_CUISINE, API_LIST_CUISINE_SUBITEMS, API_LIST_FOODITEM, API_LIST_FOODITEM_SUBITEMS, API_LIST_MEAL, API_LIST_MEAL_SUBITEMS, API_LIST_SUBITEMS, API_UPDATE_CUISINE, API_UPDATE_FOODITEM, API_UPDATE_MEAL, API_UPDATE_SUBITEMS } from '../constants';

const initialState = {
    isLoading: false,
    skip: 0,
    limit: 10,
}

// cuisine .
export const AddCuisine_API = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_CUISINE,
        method: 'POST',
        data: data,
        hideLoader: false
    }
})
export const ListCuisine_API = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_CUISINE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listCusine/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listCusine/fail',
            payload: {},
        }),
    },
})
export const DeleteCuisine_API = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_CUISINE,
        method: 'DELETE',
        data: data
    },
})

export const UPDATECuisine_API = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_CUISINE,
        method: 'PATCH',
        data: data
    },
})
export const getCuisine_API = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_CUISINE,
        method: 'POST',
        data: data
    },
})


// meal 

export const AddMEAL_API = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_MEAL,
        method: 'POST',
        data: data
    },
})
export const ListMEAL_API = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_MEAL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listMeal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listMeal/fail',
            payload: {},
        }),
    },
})

export const DeleteMEAL_API = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_MEAL,
        method: 'DELETE',
        data: data
    },
})
export const UPDATEMEAL_API = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_MEAL,
        method: 'PATCH',
        data: data
    },
})

export const GET_MEAL_API = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_MEAL,
        method: 'POST',
        data: data
    },
})

// item
export const ListItems_API = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_FOODITEM,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listItems/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listItems/fail',
            payload: {},
        }),
    },
})
export const changeSequenceMeal = (data) => ({
    type: 'API',
    payload: {
        url: API_CHANGE_SEQUENCE_MEAL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'changeSequenceMeal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'changeSequenceMeal/fail',
            payload: {},
        }),
    },
})
export const AddItem_API = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_FOODITEM,
        method: 'POST',
        data: data
    },
})
export const DeleteItem_API = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_FOODITEM,
        method: 'DELETE',
        data: data
    },
})

export const UPDATEItem_API = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_FOODITEM,
        method: 'PATCH',
        data: data
    },
})
export const GET_ITEM_API = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_FOODITEM,
        method: 'POST',
        data: data
    },
})

export const ListSubitemsAction = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_SUBITEMS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listSubitems/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listSubitems/fail',
            payload: {},
        }),
    },
})
export const AddSubitemsAction = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_ADD_SUBITEMS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addSubitems/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addSubitems/fail',
            payload: {},
        }),
    },
})
export const listCuisineAction = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_CUISINE_SUBITEMS,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listCuisine/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listCuisine/fail',
            payload: {},
        }),
    },
})
export const listMealAction = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_MEAL_SUBITEMS,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listMeal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listMeal/fail',
            payload: {},
        }),
    },
})
export const listItemAction = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_FOODITEM_SUBITEMS,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'listFooditem/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'listFooditem/fail',
            payload: {},
        }),
    },
})

export const updateSubitemAction = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_SUBITEMS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateSubitem/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'updateSubitem/fail',
            payload: {},
        }),
    },
})
export const deleteSubitemAction = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_SUBITEMS,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteSubitem/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteSubitem/fail',
            payload: {},
        }),
    },
})

export const getSubitemAction = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_SUBITEMS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getSubitem/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getSubitem/fail',
            payload: {},
        }),
    },
})
const CuisineSlice = createSlice({
    name: 'cuisineMangement',
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase('listCusine/success', (state, action) => {
            state.cuisineList = action.payload.data
        })
        builder.addCase('listMeal/success', (state, action) => {
            state.mealList = action.payload.data
        })
        builder.addCase('listItems/success', (state, action) => {
            state.itemsList = action.payload.data
        })
        builder.addCase('listSubitems/success', (state, action) => {
            state.subitemList = action.payload.data
        })
    }
})



export default CuisineSlice.reducer
