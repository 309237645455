import { useLocation, useNavigate } from "react-router-dom";
import { Button, Row, Form } from "antd";
import { RenderInput } from '../../utils/FormField';
import { useDispatch } from "react-redux";
import { updateGoatLevel } from "../../Redux/VenueProfileSlice";
import { toast } from "react-toastify";
const ViewRestaurant = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { venueId, name, address, email, goatLevel, openTableLink, phoneNumber, websiteLink, _id } = location.state.record
    const handleSubmit = (values) => {
        const data = {
            restaurantId: _id,
            goatLevel: +values.goatLevel
        }

        dispatch(updateGoatLevel(data))
            .then((response) => {
                toast.success(response.message);
                navigate("/venue-management/view-venue", {
                    state: {
                        venue: { _id: venueId }
                    }
                });
            })
            .catch((error) => {
                toast.error(error.message);
            });

    }
    return (
        <>
            <div className='shadow-paper'>
                <Form onFinish={handleSubmit} form={form} >

                    <div className='btn-group d-flex align-items-center justify-content-end'>
                        <Button type="seconadary" onClick={() => navigate(-1, {
                            state: {
                                venue: { _id: venueId }
                            }
                        })}>Back</Button>
                        <Button type="primary" htmlType="submit">Save</Button>
                    </div>

                    <div className='view-wrap'>
                        <div className='left-content'>Name:</div>
                        <div className='right-content'>{name}</div>
                    </div>
                    <div className='view-wrap'>
                        <div className='left-content'>Email:</div>
                        <div className='right-content'>{email === "" ? "N/A" : email}</div>
                    </div>

                    <div className='view-wrap'>
                        <div className='left-content'>Address:</div>
                        <div className='right-content'>{address}</div>
                    </div>
                    <div className='view-wrap'>
                        <div className='left-content'>Open table link:</div>
                        <div className='right-content'>{openTableLink === "undefined" || openTableLink === undefined || openTableLink === "" ? "N/A" : openTableLink}</div>
                    </div>
                    <div className='view-wrap'>
                        <div className='left-content'>Phone number:</div>
                        <div className='right-content'>{phoneNumber ? phoneNumber.replace(/^\+1\s*/, '')
                            .replace(/(\d{3})-(\d{3})-(\d{4})/, '($1) $2-$3') : "N/A"}</div>
                    </div>

                    <div className='view-wrap'>
                        <div className='left-content'>Website link:</div>
                        <div className='right-content'>{websiteLink === "undefined" || websiteLink === undefined || websiteLink === "" ? "N/A" : websiteLink}</div>
                    </div>
                    <div className='view-wrap'>
                        <div className='left-content'>GOAT Level:</div>
                        <div className='right-content'>{goatLevel}</div>
                    </div>
                    <Row className="mb-20">
                        <RenderInput
                            col={{ xs: 12 }}
                            name="goatLevel"
                            placeholder="Update the minimum GOAT Level"
                            label="Update the minimum GOAT Level"
                            maxLength={6}
                            rules={[
                                {
                                    required: true,
                                    message: 'Minimum GOAT Level is required!',
                                },
                                {
                                    validator: (_, value) => {
                                        if (/\s/.test(value)) {
                                            return Promise.reject('Whitespace is not allowed!');
                                        }
                                        return Promise.resolve();
                                    }
                                },
                                {

                                    pattern: /^[0-9]*$/,
                                    message: 'Only numbers are allowed!'
                                }
                            ]}
                        />
                    </Row>
                </Form>
            </div>
        </>
    );
}

export default ViewRestaurant