import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CHANGE_PASSWORD, API_LOGIN, API_SIGN_OUT, LOGIN_F, LOGIN_S, LS_AUTHTOKEN, LS_USER } from "../constants";

const initialState = {
  // Global loader for api 
  isLoading: false,

  // Auth Data
  // Auth Data
  // Auth Data
  isLoggedIn: false,
  userData: {},
};

export const loginAction = (data) => ({
  type: "API",
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: {},
    }),
  },
});

export const logoutAction = (data) => ({
  type: "API",
  payload: {
    url: API_SIGN_OUT,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "LOGOUT_SUCCESS",
      payload: data,
    }),
    error: (data) => ({
      type: "LOGOUT_FAIL",
      payload: {},
    }),
  },
});
export const GET_STATES = () => ({
  type: "API",
  payload: {
    url: `/states`,
    method: "GET",
    hideLoader: false
  },
})

export const ChangePasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_CHANGE_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "changePassword/success",
      payload: data,
    }),
    error: (data) => ({
      type: "changePassword/fail",
      payload: {},
    }),
  },
});

// Reducer
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGIN_S, (state, action) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = action.payload.data.accessToken;
      localStorage.setItem(LS_AUTHTOKEN, JSON.stringify(action.payload.data.accessToken));
      localStorage.setItem(LS_USER, JSON.stringify(action.payload.data));

      state.userData = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(LOGIN_F, (state, action) => {
      // remove items on logout
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem(LS_AUTHTOKEN);
      localStorage.removeItem(LS_USER);
      // localStorage.removeItem('fcmtoken')

      state.userData = {};
      state.isLoggedIn = false;
    });
    builder.addCase('LOGOUT_SUCCESS', (state, action) => {
      state.userData = {}
    })
  },
});

export const { loaderChange } = loginSlice.actions;
export default loginSlice.reducer;
