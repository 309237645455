import { createSlice } from '@reduxjs/toolkit'
import { API_ADD_ADMIN_RESTAURANT, API_APPROVE_RESTAURANT, API_DELETE_ADMIN_RESTAURANT, API_FRANCHISE_TOGGLE, API_GET_ALL_FRANCHISE_LIST, API_LIST_ADMIN_RESTAURANT, API_REPORTED_RESTAURANT, API_REPORTED_RESTAURANT_ACTIVE, API_RESOLVED_REPORTED_RESTAURANT, API_RESTAURANT_REQUEST_LIST, GET_APPROVE_RESTAURANT_DETAIL } from '../constants/index'


const initialState = {
    isLoading: false,
    isLoggedIn: false,
    restaurntRequestDetail: {},
    approvedRestList: { data: [], recordsTotal: 0 },
    reportedRestaurantList: { data: [], recordsTotal: 0 },
    sidebarShow: true,
    skip: 0,
    limit: 10,
}

//APPROVE restaurant 
export const approveRestaurant = (data) => ({
    type: 'API',
    payload: {
        url: API_APPROVE_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRestaurant/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRestaurant/fail',
            payload: {},
        }),
    },
})

//approve reject request
export const getApproveRestaurantList = (data) => ({
    type: 'API',
    payload: {
        url: API_RESTAURANT_REQUEST_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getApprovedRestaurantList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getApprovedRestaurantList/fail',
            payload: {},
        }),
    },
})



//Get restaurnat Detail Action
export const getRestaurantRequestDetail = (data) => ({
    type: 'API',
    payload: {

        url: GET_APPROVE_RESTAURANT_DETAIL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getRestaurantRequestDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getRestaurantRequestDetail/fail',
            payload: {},
        }),
    },
})
export const ADDRestauantByADmin = (data) => ({
    type: 'API',
    payload: {

        url: API_ADD_ADMIN_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
    },
})
export const ListRestauantByADmin = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_ADMIN_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getRestaurants/success',
            payload: data,
        }),

    },
})

export const DELETERestaurant = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_ADMIN_RESTAURANT,
        method: 'DELETE',
        data: data,
        hideLoader: false,
    },
})

//Get reported restaurant List Action
export const getReportedRsetaurantList = (data) => ({
    type: 'API',
    payload: {
        url: API_REPORTED_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'reportedRestaurantList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'reportedRestaurantList/fail',
            payload: {},
        }),
    },
})

//Resolved reported restaurant 
export const resolvedReportedRestaurant = (data) => ({
    type: 'API',
    payload: {
        url: API_RESOLVED_REPORTED_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'resolvedRestaurant/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'resolvedRestaurant/fail',
            payload: {},
        }),
    },
})

export const reportedRestaurantActiveInactive = (data) => ({
    type: 'API',
    payload: {
        url: API_REPORTED_RESTAURANT_ACTIVE,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'activeInactive/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'activeInactive/fail',
            payload: {},
        }),
    }
})

export const getAllFranchiseList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_ALL_FRANCHISE_LIST,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getAllFranchiseList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getAllFranchiseList/fail',
            payload: {},
        }),
    },
})

export const updateFrnachiseToggle = (data) => ({
    type: 'API',
    payload: {
        url: API_FRANCHISE_TOGGLE,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateFranchiseToggle/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'updateFranchiseToggle/fail',
            payload: {},
        }),
    },
})

// Reducer
const getRestaurantSlice = createSlice({
    name: 'getRestaurntList',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getRestaurants/success', (state, action) => {
            state.restaurantList = action.payload.data.data
            state.recordsTotal = action.payload.data.recordsTotal
        })
        builder.addCase('getApprovedRestaurantList/success', (state, action) => {
            state.approvedRestList = action?.payload?.data
        })
        builder.addCase('getApprovedRestaurantList/fail', (state, action) => {
            state.approvedRestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })

        builder.addCase('getRestaurantRequestDetail/success', (state, action) => {
            state.restaurntRequestDetail = action.payload.data

        })
        builder.addCase('getRestaurantRequestDetail/fail', (state, action) => {
            state.restaurntRequestDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('reportedRestaurantList/success', (state, action) => {
            state.reportedRestaurantList = action?.payload?.data
        })
        builder.addCase('reportedRestaurantList/fail', (state, action) => {
            state.reportedRestaurantList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getAllFranchiseList/success', (state, action) => {
            state.getAllFranchiseList = action.payload.data
        })
        builder.addCase('getAllFranchiseList/fail', (state, action) => {
            state.getAllFranchiseList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = getRestaurantSlice.actions
export default getRestaurantSlice.reducer
