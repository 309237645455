import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePagination = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const pageParam = queryParams.get('page') || 1;
    const searchParam = queryParams.get('search') || '';
    const pageSizeParam = queryParams.get('pageSize') || 10; // Default page size is 10
    const fillterType = queryParams.get('tabType') || "1";

    const [currentPage, setCurrentPage] = useState(Number(pageParam));
    const [searchTerm, setSearchTerm] = useState(searchParam);
    const [pageSize, setPageSize] = useState(Number(pageSizeParam));
    const [tabType, setTabTypeValue] = useState(fillterType);

    useEffect(() => {
        setCurrentPage(Number(pageParam));
        setSearchTerm(searchParam);
        setPageSize(Number(pageSizeParam));
    }, [pageParam, searchParam, pageSizeParam]);

    const onChangePage = (page) => {
        queryParams.set('page', page);
        navigate({ search: queryParams.toString() }, { replace: true });
        setCurrentPage(page);
    };

    const onSearchPagination = (search) => {
        queryParams.set('search', search);
        queryParams.set('page', 1); // Reset to first page on new search
        navigate({ search: queryParams.toString() }, { replace: true });
        setSearchTerm(search);
    };

    const onChangePageSize = (size) => {
        queryParams.set('pageSize', size);
        navigate({ search: queryParams.toString() }, { replace: true });
        setPageSize(size);
    };

    const clearSearch = () => {
        queryParams.delete('search');
        queryParams.set('page', 1); // Reset to first page when search is cleared
        navigate({ search: queryParams.toString() }, { replace: true });
        setSearchTerm('');
    };

    const onFillterChange = (value) => {
        queryParams.set('tabType', value)
        navigate({ search: queryParams.toString() });
        setTabTypeValue(value)
    }

    return { tabType, onFillterChange, currentPage, onChangePage, searchTerm, onSearchPagination, clearSearch, pageSize, onChangePageSize };
};
