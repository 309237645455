import { createSlice } from "@reduxjs/toolkit";
import { API_getUsersByCity, API_getUsersByState, API_getUserViewSAnalytics, API_GET_USERS_BY_AGE, API_Goat_Average, getUsersByCity, getUsersByState, getUserViewSAnalytics, GET_USERS_BY_AGE, goatAverage, API_LOWEST_HIGHEST_SEARCH_ANALYTICS, getLowestHighestSearchHits, API_GOAT_GIVEN_LOST_ANALYTICS, getGoatGivenLost } from "../constants";

const initialState = {
    restaurantList: [],
    recordsTotal: 0
};

export const GetUserByAge = (data) => ({
    type: "API",
    payload: {
        url: API_GET_USERS_BY_AGE,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: GET_USERS_BY_AGE,
            payload: data,
        })
    },
});
export const GetUserByCity = (data) => ({
    type: "API",
    payload: {
        url: API_getUsersByCity,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: getUsersByCity,
            payload: data,
        })
    },
});
// export const getRestaurantIdAndName = (data) => ({
//     type: "API",
//     payload: {
//         url: API_getRestaurantIdAndName,
//         method: "GET",
//         data: data,
//         hideLoader: false,
//         success: (data) => ({
//             type: getRestaurantIdAndNameE,
//             payload: data,
//         })
//     },
// });
export const getUserbyState = (data) => ({
    type: "API",
    payload: {
        url: API_getUsersByState,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: getUsersByState,
            payload: data,
        })
    },
});
// export const getSearchedCuisine = (data) => ({
//     type: "API",
//     payload: {
//         url: API_getSearchedCuisines,
//         method: "POST",
//         data: data,
//         hideLoader: false,
//         success: (data) => ({
//             type: getSearchedCuisines,
//             payload: data,
//         })
//     },
// });
// export const getFoodSearched = (data) => ({
//     type: "API",
//     payload: {
//         url: API_getSearchedCuisines,
//         method: "POST",
//         data: data,
//         hideLoader: false,
//         success: (data) => ({
//             type: getfoodSearched,
//             payload: data,
//         })
//     },
// });
// export const getMealSearched = (data) => ({
//     type: "API",
//     payload: {
//         url: API_getSearchedCuisines,
//         method: "POST",
//         data: data,
//         hideLoader: false,
//         success: (data) => ({
//             type: getmealSearched,
//             payload: data,
//         })
//     },
// });
export const getUserViewAnalytic = (data) => ({
    type: "API",
    payload: {
        url: API_getUserViewSAnalytics,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: getUserViewSAnalytics,
            payload: data,
        })
    },
});
export const GoatAverage = (data) => ({
    type: "API",
    payload: {
        url: API_Goat_Average,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: goatAverage,
            payload: data,
        })
    },
});

export const getAnalyticLowHighSearch = (data) => ({
    type: "API",
    payload: {
        url: API_LOWEST_HIGHEST_SEARCH_ANALYTICS,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: getLowestHighestSearchHits,
            payload: data,
        })
    },
});

export const getAnalyticGoatSearch = (data) => ({
    type: "API",
    payload: {
        url: API_GOAT_GIVEN_LOST_ANALYTICS,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: getGoatGivenLost,
            payload: data,
        })
    },
});


const AnalyticsSlice = createSlice({
    name: "AnalyticSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(GET_USERS_BY_AGE, (state, action) => {
            state.usersByage = action.payload.data
        });
        // builder.addCase(getRestaurantIdAndNameE, (state, action) => {
        //     state.restaurantList = action.payload.data.restaurantList
        // });
        builder.addCase(getUsersByState, (state, action) => {
            state.stateAnalytics = action.payload.data
        });
        builder.addCase(getUsersByCity, (state, action) => {
            state.cityAnalytics = action.payload.data
        });
        // builder.addCase(getSearchedCuisines, (state, action) => {
        //     state.searchedCuisines = action.payload.data
        // });
        // builder.addCase(getfoodSearched, (state, action) => {
        //     state.foodCuisines = action.payload.data
        // });
        // builder.addCase(getmealSearched, (state, action) => {
        //     state.mealCuisines = action.payload.data
        // });
        builder.addCase(getUserViewSAnalytics, (state, action) => {
            state.userViewanalytic = action.payload.data
        });
        builder.addCase(goatAverage, (state, action) => {
            state.goatAverage = action.payload.data
        });
        builder.addCase(getLowestHighestSearchHits, (state, action) => {
            state.highLowSearchAnalytic = action.payload
        });
        builder.addCase(getGoatGivenLost, (state, action) => {
            state.goatGivenLostAnalytic = action.payload.data
        });
    },
});

export const { loaderChange } = AnalyticsSlice.actions;
export default AnalyticsSlice.reducer;
