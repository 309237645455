import { createSlice } from "@reduxjs/toolkit";
import { API_getUserBycity, API_getUserBystate, API_GUIDE_ALL_USERS, API_GUIDE_ANALYTICS, API_GUIDE_DELETE_USER, API_GUIDE_GET_USER, API_GUIDE_UPDATE_USER, GET_APPROVE_REJECT_REQUEST, GET_GUIDE_REQUEST_LIST } from "../constants";

const initialState = {
    status: "",
    goatViewuser: [],
    error: null,
    guideRequestList: { data: [], recordsTotal: 0 },
};


export const getAllGuideUsers = (data) => ({
    type: "API",
    payload: {
        url: API_GUIDE_ALL_USERS,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: "goatUsers/Sucesss",
            payload: data,
        })
    },
});
export const getdeleteGuide = (data) => ({
    type: "API",
    payload: {
        url: API_GUIDE_DELETE_USER,
        method: "DELETE",
        data: data,
        hideLoader: false
    },
});
export const getViewGuideUser = (data) => ({
    type: "API",
    payload: {
        url: API_GUIDE_GET_USER,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: "goatUsersView/Sucesss",
            payload: data,
        })
    },
});
export const UPDATEGuideUser = (data) => ({
    type: "API",
    payload: {
        url: API_GUIDE_UPDATE_USER,
        method: "PATCH",
        data: data,
        hideLoader: false
    },
});
export const guideAnalytics = (data) => ({
    type: "API",
    payload: {
        url: API_GUIDE_ANALYTICS,
        method: "POST",
        data: data,
        hideLoader: false
    },
});
export const getUserBycity = (data) => ({
    type: "API",
    payload: {
        url: API_getUserBycity,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: "getUserBycity/Sucesss",
            payload: data,
        })
    },
});
export const getUserByState = (data) => ({
    type: "API",
    payload: {
        url: API_getUserBystate,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: "getUserBystate/Sucesss",
            payload: data,
        })
    },
});

export const getGuideRequestList = (data) => ({
    type: 'API',
    payload: {
        url: GET_GUIDE_REQUEST_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getGuideRequestList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getGuideRequestList/fail',
            payload: {},
        }),
    },
})

export const approveRejectGuide = (data) => ({
    type: 'API',
    payload: {
        url: GET_APPROVE_REJECT_REQUEST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRejectGuide/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRejectGuide/fail',
            payload: {},
        }),
    },
})


// Reducer
const GoatGuideSlice = createSlice({
    name: "cms",
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase('goatUsers/Sucesss', (state, action) => {
            state.goatuserList = action.payload.data
        })
        builder.addCase('goatUsersView/Sucesss', (state, action) => {
            state.goatViewuser = action.payload.data
        })
        builder.addCase('getUserBycity/Sucesss', (state, action) => {
            state.goatUserBycity = action.payload.data
        })
        builder.addCase('getUserBystate/Sucesss', (state, action) => {
            state.goatUserBystate = action.payload.data
        })
        builder.addCase('getGuideRequestList/success', (state, action) => {
            state.guideRequestList = action?.payload?.data
        })
        builder.addCase('getGuideRequestList/fail', (state, action) => {
            state.guideRequestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
    },
});

export const { loaderChange } = GoatGuideSlice.actions;
export default GoatGuideSlice.reducer;
