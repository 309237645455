import React from "react"
import { Col, Form, Input, Checkbox, Select, DatePicker, AutoComplete as AutoCompleteComp } from "antd"
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import { PLACE_KEY } from "../constants";


const onChange = (value) => {
};

export const RenderInput = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <Input defaultValue={props?.defaultValue} value={props?.value} placeholder={props?.placeholder} type={props?.type} onChange={props.onChange} disabled={props.disabled} readOnly={props.readonly === true ? true : false} maxLength={props.maxLength !== undefined ? props.maxLength : false} />
        </Form.Item>
      </Col>
    </>
  )

}

export const RenderPassword = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <Input.Password placeholder={props.placeholder} />
        </Form.Item>
      </Col>
    </>
  )
}
export const RenderTextArea = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <TextArea rows={props.rows} />
        </Form.Item>
      </Col>
    </>
  )
}

export const RenderSelect = (props) => {
  return (
    <>
      <Col  {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={props.className}
          rules={props.rules}
        >
          <Select
            showSearch
            onChange={props.onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={props.value}
            defaultValue={props.value}
            onSearch={props.onSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            allowClear={props.allowClear}
          >
            {props?.optionLabel?.map((item, i) => (
              <option key={i} value={item._id} label={item.name}>{item.name}</option>
            ))}
          </Select>
        </Form.Item>
      </Col >
    </>
  )
}

export const RenderDatePicker = (props) => {
  return (
    <>
      <Col  {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          rules={props.rules}

        >
          <DatePicker
            format="MM/DD/YYYY"
            onChange={props.onChange}
            allowClear={false}
            disabledDate={props.disabledDate}
          />
        </Form.Item>
      </Col>
    </>
  )
}

export const RenderCheckbox = (props) => {
  return (
    <>
      <Col  {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={props.rules}
        >
          <Checkbox
            onChange={onChange}
            onClick={props.onClick}
            checked={props.checked}
          >{props.formLabel}</Checkbox>
        </Form.Item>
      </Col>
    </>
  )
}

export function LocationSearchInput(
  {
    disabled,
    form,
    col,
    name,
    label,
    rules,
    setVenueName,
    setFormattedAddress,
    setNumber,
    setWebsite,
    venueName,
    setLat,
    setLng,
    setState,
    setCity,
    setPlaceId
  }) {
  return (
    <Col {...col}>
      <Form.Item
        name={name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={label}
        rules={rules}
      >
        <Autocomplete
          value={venueName}
          disabled={disabled}
          className="ant-input w-100"
          onChange={(e) => setVenueName(e.target.value)}
          apiKey={PLACE_KEY}
          options={{
            types: ['food', 'restaurant', 'cafe'],
            componentRestrictions: { country: "us" },
            fields: ['ALL']
          }}
          onPlaceSelected={(place) => {
            const loc = place?.formatted_address.split(', ')
            const countryy = loc.pop();
            form.setFieldsValue({
              name: place?.name,
              address: loc.toString(),
              websiteLink: place?.website,
              // phoneNumber: place?.formatted_phone_number
            })
            setPlaceId(place?.place_id)
            setVenueName(place?.name)
            setFormattedAddress(loc.toString())
            // setNumber(place?.formatted_phone_number)
            setWebsite(place?.website)
            setLat(place?.geometry?.location?.lat())
            setLng(place?.geometry?.location?.lng())
            setState(place?.address_components?.find((type) => type.types.includes("administrative_area_level_1"))?.long_name)
            setCity(place?.address_components?.find((type) => type.types.includes("locality"))?.long_name)
          }}
        />
      </Form.Item>
    </Col>
  )
}

export const RenderDatePicker2 = (props) => {
  return (
    <>
      <Col  {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          rules={props.rules}

        >
          <DatePicker
            value={props.value}
            format="MM/DD/YYYY hh:mm:A"
            onChange={props.onChange}
            allowClear={false}
            disabledDate={(current) => {
              let customDate = moment().format("MM/DD/YYYY");
              return current && current.isBefore(moment(customDate, 'MM/DD/YYYY').subtract(0, "day"))
            }}
            showTime={props.showTime}
            use24Hours={props.use24Hours}
          />
        </Form.Item>
      </Col>
    </>
  )
}


export function CitySearchInput(
  {
    disabled,
    locationAddress,
    setLocationAddress,
    form,
    col,
    name,
    label,
    rules,
    setState,
    setCity,
    setLat,
    setLng,
    city,
    placeholder
  }) {
  return (
    <Col {...col}>
      <Form.Item
        name={name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={label}
        rules={rules}
      >
        <Autocomplete
          value={city}
          disabled={disabled}
          className="ant-input w-100"
          placeholder={placeholder}
          onChange={(e) => setCity(e.target.value)}
          apiKey={PLACE_KEY}
          options={{
            types: ['(cities)'],
            componentRestrictions: { country: "us" },
          }}
          onPlaceSelected={(place) => {
            form.setFieldsValue({
              city: place?.address_components?.find((type) => type.types.includes("locality"))?.long_name
            })
            setState(place?.address_components?.find((type) => type.types.includes("administrative_area_level_1"))?.long_name)
            setCity(place?.address_components?.find((type) => type.types.includes("locality"))?.long_name)
            setLat(place?.geometry?.location?.lat())
            setLng(place?.geometry?.location?.lng())
          }}
        />
      </Form.Item>
    </Col>
  )
}

export const RenderAutoComplete = (props) => {
  return (
    <>
      <Col  {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          initialValue={props.defaultValue}
          rules={props.rules}
        >
          <AutoCompleteComp
            value={props.value}
            options={props.options}
            onSelect={props.onSelect}
            onSearch={props.onSearch}
            onChange={props.onChange}
            placeholder="Franchise Name"
            filterOption={true}
            disabled={props.disabled}
            virtual={true}
          >
          </AutoCompleteComp>
        </Form.Item>
      </Col>
    </>
  )
}