import { createGlobalStyle } from "styled-components";
import { device } from './breakpoints';

const Theme = createGlobalStyle`

    /* --- Collapse --- */
    .ant-collapse {
        background-color: transparent;
        border: none;
        .ant-collapse-content-box{
            padding: 30px 20px;
        }
        .ant-collapse-item{
            margin-bottom: 20px;
            border: none;
            border-radius: 8px;
            &.ant-collapse-item-active{
                .ant-collapse-header{
                    border-radius: 8px 8px 0 0 ;
                    border-bottom:1px solid rgba(0,0,0,.05);
                    .ant-collapse-expand-icon{
                        transform: rotate(-180deg);
                        -ms-transform: rotate(-180deg);
                        -moz-transform: rotate(-180deg);
                        -webkit-transform: rotate(-180deg);
                    }
                }
                &:last-child{
                    .ant-collapse-header{
                        border-radius: 8px 8px 0 0 ;
                    }
                }
            }
            &:last-child{
                border-radius: 8px;
                .ant-collapse-header{
                    border-radius: 8px;
                }
            }
            .ant-collapse-header{
                justify-content: space-between;
                flex-direction: row-reverse;
                border-bottom:1px solid transparent;
                font-size: 16px;
                background-color: rgb(240, 243, 245);
                color:#000;
                padding: 12px 20px;
                border-radius: 8px;
                font-family: Inter, sans-serif;
                font-weight: 600;
                .ant-collapse-expand-icon{
                    transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    padding:0;
                    .ant-collapse-arrow{
                        font-size: 16px;
                        font-weight: 600;
                        svg{
                            *{
                                fill: #000;
                            }
                        }
                    }
                }
            }
            .ant-collapse-content{
                border: none;
                background-color: rgb(240, 243, 245);
                border-radius: 0 0 8px 8px  ;
            }
        }
        
    }
    .search-group{
        margin-bottom:30px;
        .ant-form-item{
            max-width:350px;
            width:100%;
            margin-right: 20px;
           &.combine-item{
            @media only screen and (${device.sm}){
                max-width:100%;
                margin: 0 0 10px;
            } 
           }
        }
        &.search-wrap{
            flex-wrap: wrap;
            .ant-form-item{
                max-width: 100%;
                margin: 0;
            }
            .custom-row{
                width: 50%;
                @media only screen and (${device.md}){
                    width: 100%;
                    row-gap: 20px;
                    margin-bottom: 20px;
                }
            }
        }
        &.goat-btn{
            .ant-btn{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
            @media only screen and (${device.sm}){
               flex-wrap: wrap;
               .ant-form-item{
                    max-width: 100%;
                    margin: 0 0 20px;
               }
            }
        }
    }
    .btn-group{
        margin-bottom:30px;
        button{
            margin-right:15px;
            &:last-child{
                margin-right:0;
            }
        }
    }
    .btn-boxshadow{
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -ms-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
    } 
   

`;

export default Theme