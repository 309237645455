import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Upload,
} from "antd";
import React, { useState } from "react";
import { Wrapper } from "../Login/loginCss";
import {
  LocationSearchInput,
  RenderInput,
} from "../../utils/FormField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UploadIcon } from "../../svg/index";

import { toast } from "react-toastify";
import {
  ADDRestauantByADmin,
} from "../../Redux/RestaurantManagementSlice";
import { PatternFormat } from "react-number-format";

const AddRestaurant = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [value, setValue] = useState();
  const [restaurantImage, setrestaurantImage] = useState([]);
  const [coverImage, setcoverImage] = useState([]);
  // const [placeId, setPlaceId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [venueName, setVenueName] = useState();
  // const [formattedAddress, setFormattedAddress] = useState();
  // const [website, setWebsite] = useState();
  // const [lat, setLat] = useState("");
  // const [lng, setLng] = useState("");
  // const [city, setCity] = useState();
  // const [state, setState] = useState();
  // const [allChainRestaurant, setAllChainRestaurant] = useState([]);

  const handleCoverImage = ({ file, fileList }) => setcoverImage(fileList);

  const handleSubmit = (values) => {
    setIsSubmitting(true)
    // let chainId = allChainRestaurant.find((item) => {
    //   return item.value === inputValue;
    // });

    let formData = new FormData();
    formData.append("name", values.name);
    // formData.append("placeId", placeId);
    // if (chainId?.id) {
    //   formData.append("chain", chainId?.id);
    // } else {
    //   formData.append("chain", inputValue);
    // }
    formData.append("isRestaurantFranchise", values.isRestaurantFranchise);
    if (values.email !== undefined && values.email !== "") {
      formData.append("email", values.email);
    }

    if (values.websiteLink !== undefined && values.websiteLink !== "") {
      formData.append("websiteLink", values.websiteLink);
    }
    formData.append("shortAddress", values.address);
    // formData.append("city", city);
    // formData.append("state", state);
    if (values.openTableLink !== undefined && values.openTableLink !== "") {
      formData.append("openTableLink", values.openTableLink);
    }

    formData.append("phoneNumber", values.phoneNumber);
    formData.append("lat", values.lat);
    formData.append("lng", values.lng);

    if (restaurantImage.length > 0) {
      formData.append("restaurantLogo", restaurantImage[0].originFileObj);
      formData.append("isResturantImageRemove", false);
    } else {
      formData.append("isResturantImageRemove", true);
    }

    if (coverImage.length > 0) {
      formData.append("coverImage", coverImage[0].originFileObj);
    }
    formData.append("isCoverImageRemove", false);

    dispatch(ADDRestauantByADmin(formData))
      .then((response) => {
        toast.success(response.message);
        navigate("/restaurants");
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsSubmitting(false)
      });
  };



  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleChange = ({ file, fileList }) => {
    setrestaurantImage(fileList);
  };
  const handleDeleteCoverImage = () => setcoverImage([]);
  const handleDelete = () => setrestaurantImage([]);
  const uploadButton = (
    <div>
      <UploadIcon />
    </div>
  );

  const validateUrl = (rule, value, callback) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    if (value && !urlPattern.test(value)) {
      callback("Please enter a valid URL");
    } else {
      callback();
    }
  };

  // let useCount = allChainRestaurant.find((item) => {
  //   return item.value === inputValue;
  // });

  // useEffect(() => {
  //   dispatch(getAllChianList())
  //     .then((response) => {
  //       const optionData = response?.data.map((item) => ({
  //         value: item.name, // This will be used as the value when an option is selected
  //         label: item.name,
  //         id: item._id, // This will be displayed in the dropdown
  //         count: item.useCount,
  //       }));
  //       setAllChainRestaurant(optionData);
  //     })
  //     .catch((error) => toast.error(error.message));
  // }, [dispatch]);



  return (
    <Wrapper>
      <div className="shadow-paper">
        {/* <Button type="default" htmlType="submit" onClick={() => navigate('/venue-info')}></Button> */}
        <div
          className="page-header page-sub-header"
          style={{ marginBottom: "35px" }}
        >
          <h2>Venue Information</h2>
        </div>
        <div></div>
        <div className="shadow-form">
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[50, 0]}>
              <Col lg={12} xs={24}>
                <Row gutter={[0, 36]}>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      name="isRestaurantFranchise"
                      label="Is this restaurant a franchise?"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Franchise field is required.",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {/* {value === true ? (
                    <>
                      <RenderAutoComplete
                        col={{ lg: 18, xs: 24 }}
                        name="chain"
                        placeholder="Chain Name"
                        label="Chain Name"
                        rules={[
                          {
                            required: true,
                            message: "Chain name field is required!",
                          },
                        ]}
                        value={inputValue}
                        options={allChainRestaurant}
                        onSelect={(selectedValue) => {
                          // Do something with the selected value
                          setInputValue(selectedValue);
                        }}
                        onSearch={handleSearch}
                      />
                      <Form.Item
                        label="Chain Count"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <div
                          className="use-counter"
                          style={{ textAlign: "center" }}
                        >
                          <span>{useCount?.count ? useCount?.count : 0}</span>
                        </div>
                      </Form.Item>
                    </>
                  ) : null} */}

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="name"
                    placeholder="Venue Name"
                    label="Venue Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Address.",
                      },
                    ]}
                  />
                  {/* <LocationSearchInput
                    setState={setState}
                    setCity={setCity}
                    venueName={venueName}
                    setLat={setLat}
                    setLng={setLng}
                    setPlaceId={setPlaceId}
                    setVenueName={setVenueName}
                    setFormattedAddress={setFormattedAddress}
                    setWebsite={setWebsite}
                    form={form}
                    col={{ lg: 24, xs: 24 }}
                    name="name"
                    placeholder="Venue Name"
                    label="Venue Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Name.",
                      },
                    ]}
                  /> */}

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter your valid E-mail.",
                      },
                    ]}
                  />

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="address"
                    placeholder="Venue Address"
                    label="Venue Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Address.",
                      },
                    ]}
                  />
                  <Col lg={24} xs={24}>

                    <Row gutter={[12, 36]}>

                      <RenderInput
                        col={{ lg: 12, xs: 12 }}
                        name="lat"
                        placeholder="Enter your Latitude"
                        label="Latitude"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Latitude.",
                          },
                          {
                            validator: (_, value) => {
                              if (!value || isNaN(value)) {
                                return Promise.reject("Latitude must be a number.");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      />

                      <RenderInput
                        col={{ lg: 12, xs: 12 }}
                        name="lng"
                        placeholder="Enter your Longitude"
                        label="Longitude"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Longitude.",
                          },
                          {
                            validator: (_, value) => {
                              if (!value || isNaN(value)) {
                                return Promise.reject("Longitude must be a number.");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      />
                    </Row>
                  </Col>
                  {/* <LocationSearchInput
                    setState={setState}
                    setCity={setCity}
                    venueName={venueName}
                    setLat={setLat}
                    setLng={setLng}
                    setPlaceId={setPlaceId}
                    setVenueName={setVenueName}
                    setFormattedAddress={setFormattedAddress}
                    setWebsite={setWebsite}
                    form={form}
                    col={{ lg: 24, xs: 24 }}
                    name="address"
                    placeholder="Venue Address"
                    label="Venue Address"
                    value={formattedAddress}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Address.",
                      },
                    ]}
                  /> */}

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="websiteLink"
                    placeholder="Venue website hyperlink"
                    label="Venue website Hyperlink"
                    type="text"
                    rules={[{ validator: validateUrl }]}
                  // value={website}
                  // disabled
                  />
                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="openTableLink"
                    placeholder="Venue Reservation Link (OpenTable, Resy, Reserve with Google, etc.)"
                    label="Reservation Link"
                    type="text"
                    rules={[{ validator: validateUrl }]}
                  />
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row gutter={[0, 36]}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Venue Phone Number"
                    placeholder="Venue phone number"
                    name="phoneNumber"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value) {
                            const phoneNumber = value.replace(/\D/g, '');
                            if (phoneNumber.length !== 10) {
                              const error = new Error('Please enter valid phone number');
                              return Promise.reject(error);
                            }
                          } else {
                            const error = new Error('Please enter phone number');
                            return Promise.reject(error);
                          }
                        }
                      }
                    ]}
                  >
                    <PatternFormat
                      name="phoneNumber"
                      placeholder="Venue phone number"
                      // addonBefore={prefixSelector}
                      format="(###) ###-####"
                      customInput={Input}
                    />
                  </Form.Item>

                  <Col lg={24} xs={24}>
                    <Form.Item
                      label="Add Restaurant Logo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="restaurantLogo"
                    >
                      <Upload
                        listType="picture-card"
                        fileList={restaurantImage || []}
                        maxCount={1}
                        multiple={false}
                        onChange={handleChange}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {restaurantImage.length > 0 ? null : uploadButton}
                      </Upload>
                      {restaurantImage.length <= 0 ? null :
                        <Button onClick={handleDelete}>Delete</Button>
                      }
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      label="Cover Image"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="coverImage"
                    >
                      <Upload
                        listType="picture-card"
                        fileList={coverImage}
                        maxCount={1}
                        onChange={handleCoverImage}
                        multiple={false}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {coverImage.length > 0 ? null : uploadButton}
                      </Upload>
                      {coverImage.length <= 0 ? null :
                        <Button onClick={handleDeleteCoverImage}>Delete</Button>
                      }
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <div className="btn-group d-flex align-items-center justify-content-end">
                      <Button
                        type="default"
                        onClick={() => navigate("/restaurants")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default AddRestaurant;
