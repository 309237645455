import { createSlice } from '@reduxjs/toolkit'
import { API_ADD_FRANCHISE, API_DELETE_FRANCHISE, API_GET_FRANCHISE, API_LIST_FRANCHISE, API_UPDATE_FRANCHISE } from '../constants/index'


// const projectName = "GOAT-Grub";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    // userDetail: {},
    sidebarShow: true,
    // userList: { data: [], recordsTotal: 0 },
}

//Get User List Action
export const getFranchiseList = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_FRANCHISE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getFranchiseList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getFranchiseList/fail',
            payload: {},
        }),
    },
})

export const addFranchise = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_FRANCHISE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addFranchise/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addFranchise/fail',
            payload: {},
        }),
    },
})
//Get User Detail Action
export const getFranchiseDetail = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_FRANCHISE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getFranchiseDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getFranchiseDetail/fail',
            payload: {},
        }),
    },
})

//User Edit Action
export const franchiseEdit = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_FRANCHISE,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'franchiseEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'franchiseEdit/fail',
            payload: {},
        }),
    },
})

//User Delete Action
export const franchiseDelete = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_FRANCHISE,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'franchiseDelete/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'franchiseDelete/fail',
            payload: {},
        }),
    }
})


// Reducer
const FranchiseSlice = createSlice({
    name: 'FranchiseSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getFranchiseList/success', (state, action) => {
            state.franchiseList = action.payload.data
        })
        builder.addCase('getFranchiseList/fail', (state, action) => {
            state.franchiseList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('addFranchise/success', (state, action) => {
            state.addFranchise = action.payload.data
        })
        builder.addCase('addFranchise/fail', (state, action) => {
            state.addFranchise = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getFranchiseDetail/success', (state, action) => {
            state.getFranchiseDetail = action.payload.data
        })
        builder.addCase('getFranchiseDetail/fail', (state, action) => {
            state.getFranchiseDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('franchiseEdit/success', (state, action) => {
            state.franchiseEdit = action.payload.data
        })
        builder.addCase('franchiseEdit/fail', (state, action) => {
            state.franchiseEdit = {}
            state.isLoggedIn = false
        })

    },
})

export const { loaderChange } = FranchiseSlice.actions
export default FranchiseSlice.reducer
