import { createGlobalStyle } from "styled-components";

export const CommonFormFiled = createGlobalStyle`

    .ant-select-selection {
        :placeholder-shown {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        &:placeholder-shown {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        ::placeholder {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        color: black !important;
        &::-webkit-input-placeholder {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        &:placeholder {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        :placeholder {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
        ::-webkit-input-placeholder {
            font-family: 'Inter';font-weight: 400;font-size: 14px;color:#B3B3B3 !important;
        }
    }

    ::-webkit-input-placeholder {
        font-family: 'Inter';font-weight:400;font-size: 14px;color:#B3B3B3 !important;   
    }

         
    .ant-form-item{
        margin:0;
        .ant-form-item-label {
            label{
                height:auto;
                font-family: 'SF Pro Display';
                font-weight: 500;
                font-size: 15px;
                color: #242424;
                &:before{
                    display:none !important;
                }
            }
        }
        .ant-form-item-control{
            textarea.ant-input {
                height:120px;
                resize:none;
            }
            .ant-input{
                background-color: #fff;
                border: 1px solid #e4e7ea;
                border-radius: 0;
                padding: 6px 12px;
                box-shadow: none;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.14px;
                color: #000000;
                height: 40px;
                &:focus{
                    border: 1px solid #F68C3D !important;
                    box-shadow:none !important;
                }
                
            }
            .ant-input-affix-wrapper {
                border: 1px solid #e4e7ea;
                box-shadow: none !important;
                padding: 0;
                position: relative;
                border-radius: 0;
                .ant-input {
                    padding: 6px 40px 6px 12px;
                    border: none;
                }
                .ant-input-suffix{
                    position: absolute;
                    right: 17px;
                    top: 0;
                    bottom: 0;
                }
            }
            .ant-select {
                .ant-select-focused{
                    border: 1px solid #F68C3D !important;
                    box-shadow:none !important;
                }
                &:hover {
                    .ant-select-selector{
                        border: 1px solid #F68C3D !important;
                        box-shadow:none !important;
                    }
                }
                &.ant-select-single{
                    height: auto !important;
                    min-height: 32px;                 }
            }
            
            .ant-select-selector{
                background-color: #fff !important;
                border: 1px solid #e4e7ea !important;
                border-radius: 0;
                padding: 6px 12px;
                box-shadow: none !important;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.14px;
                color: #000000;
                height: 40px;
                display: flex;
                align-items: center;
                
                .ant-select-selection-search-input{
                    height: 100%;
                }
            }
            .ant-picker{
                background-color: #fff;
                border: 1px solid #e4e7ea;
                border-radius: 0;
                padding: 6px 12px;
                box-shadow: none;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.14px;
                color: #000000;
                height: 40px;
                width: 100%;
            }
        }
    }

    .ant-btn.ant-btn-primary{
        background-color: #F68C3D;
        border-radius: 8px;
        font-family: 'Inter';
        font-weight: 600;
        color:var(--white--color);
        padding:8px 20px;
        font-size:14px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            margin-left: 12px;
            max-width:14px;
        }
        &:hover,&:focus{
            background-color: #F68C3D !important; 
        }
        &.disable{
            background-color: #F9B786 !important;
        }
        
    }
    .ant-btn.ant-btn-seconadary{
        background-color:#E6E8F0;
        border-radius: 8px;
        font-family: 'Inter';
        font-weight: 600;
        color:var(--black--color);
        padding:8px 20px;
        font-size:14px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            margin-left: 12px;
            max-width:14px;
        }
        &:hover,&:focus{
            background-color: #E6E8F0 !important;  
            color:var(--black--color);
        }
        &.disable{
            background-color:#E6E8F0 !important;
            color:var(--black--color);
        }
    }
    .ant-btn.ant-btn-default{
        padding: 0;
        border: none;
        box-shadow: none;
        font-family: 'SF Pro Display';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #F68C3D !important;
        background: none;
        &:hover,&:focus{
            color: #F68C3D !important; 
        }
        &:disabled{
            background:none;
            color: rgba(55, 65, 81, 0.48);
        }
    }
  
   
    .ant-upload-wrapper {
        .ant-upload {
            border-radius:0 !important;
        }
    }

    .ant-progress{
        margin: 0;
        margin-inline-end:0;
        .ant-progress-inner{
            background-color: #F4F4F5;
            .ant-progress-bg{
                background-color: #E37400;
            }
        }
    }

    .view-btn{
        padding: 8px 15px;
        .ant-btn.ant-btn-default{
            font-family: 'SF Pro Display';
            font-weight: 500;
            font-size: 13px;
            color: #00A651 !important;
            &.active{
                svg{
                    transform: rotate(-180deg);
                    -ms-transform:  rotate(-180deg);
                    -moz-transform:  rotate(-180deg);
                    -webkit-transform:  rotate(-180deg);
                }
                .all{
                    display: none;
                }
                .less{
                    display: inline-block;
                }
            }
            svg{
                margin-left: 18px;
            }
            .less{
                display: none;
                margin-left:3px;
            }
        }       
    }
    .ant-select-dropdown{
        padding: 9px 0;
        &.view-pop{
            min-width: 200px !important;
            .ant-select-item{
                text-align: center;
            }
        }
        .ant-select-item{
            background-color: #fff;
            padding: 5px 35px;
            min-height: 45px;
            align-items: center;
            border-radius: 0;
            .ant-select-item-option-content{
                font-family: 'SF Pro Display';
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #1F2D4A;
            }
            &.ant-select-item-option-active,&.ant-select-item-option-selected{
                background-color: rgba(55, 65, 81, 0.08);
            }
            
        }
    }
`;