import { createSlice } from '@reduxjs/toolkit'
import { API_GET_SUGGESTIONS_LIST, API_DELETE_SUGGESTIONS } from '../constants/index'


const initialState = {
    isLoading: false,
    isLoggedIn: false,
    sidebarShow: true,
    suggestionsList: { data: [], recordsTotal: 0 },
    skip: 0,
    limit: 10,

}

//Get suggetions List
export const getSuggestionsList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_SUGGESTIONS_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getSuggetionsList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getSuggetionsList/fail',
            payload: {},
        }),
    },
})


//suggetions Delete Action
export const suggetionsDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_SUGGESTIONS,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'suggetionsDeleteAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'suggetionsDeleteAction/fail',
            payload: {},
        }),
    }
})


// Reducer
const SuggetionsSlice = createSlice({
    name: 'SuggetionsSlice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getSuggetionsList/success', (state, action) => {
            state.suggestionsList = action.payload.data
        })
        builder.addCase('getSuggetionsList/fail', (state, action) => {
            state.suggestionsList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = SuggetionsSlice.actions
export default SuggetionsSlice.reducer
