import { createGlobalStyle } from "styled-components";
import { device } from './breakpoints';

const Common = createGlobalStyle`

//Fonts Size

// h1 {
    
// }
// h2 {
// }

// h3 {
   
// }
// h4 {
    
// h5 {
    
// }
// p {
    
// }

/*--- Margin and Padding ---*/

//padding
.p-0{
    padding: 0;
}
.pr-0{
    padding-right: 0;
}
.pl-0{
    padding-left: 0;
}
.pt-0{
    padding-right: 0;
}
.pb-0{
    padding-left: 0;
}

// margin
.m-0{
    margin: 0;
}
.mr-0{
    margin-right: 0;
}
.ml-0{
    margin-left: 0;
}
.mt-0{
    margin-right: 0;
}
.mb-0{
    margin-left: 0;
}
.mt-20{
    margin-top: 20px;
}
.mb-20{
    margin-bottom: 20px;
}



.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: --success--color;
}

.text-danger {
    color: --danger--color;
    margin-top: 5px;
    line-height: 1;
}

.text-warning {
    color: --warning--color;
}

.text-info {
    color: --info--color;
}


// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}

.container{
    width: 1290px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.container-full {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-dropdown {
    .ant-dropdown-menu{
        padding: 0;
        border-radius: 0;
        border: 1px solid rgb(200, 206, 211);
        box-shadow: none;
        .ant-dropdown-menu-item{
            padding: 8px 20px;
            font-family: Inter;
            font-weight: 400;
            font-size: 14px;
            color:var(--black--color);
            border-radius: 0;
            border-bottom: 1px solid rgb(200, 206, 211);
            &:last-child{
                border: none;
            }
            &:hover,&.ant-dropdown-menu-item-selected{
                background-color: var(--theme--color);
                color:var(--white--color);
            }
        }
    }
}

.change-form{
    max-width: 600px;
    width: 100%;
}

/* --- View Pages --- */
.view-wrap{
    display:flex;
    align-items: center;
    flex-wrap:wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
    .left-content{
        font-weight:600;
        color:var(--black--color);
        width: 200px;
        @media only screen and (${device.xs}){
            width:100%;
            margin-bottom:8px;
        } 
    }
    .right-content{
        font-weight:400;
        color:var(--black--color);
        width:calc(100% - 200px);
        .person-image{
            width: 80px;
            height:80px;
            img{
                width:100%;
                height:100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }
    }
}

/* --- Common Box --- */

.box-wrap{
    display: flex;
    flex-wrap:wrap;
    margin-left:-15px;
    margin-right:-15px;
    .box-col{
        flex: 1 0 0%;
        padding-left:15px;
        padding-right:15px;
        @media only screen and (${device.lg}){
            flex: 0 0 33.33%;
            max-width:33.33%;
            margin-bottom:30px;
        } 
        @media only screen and (${device.sm}){
            flex: 0 0 100%;
            max-width:100%;
        } 
    }
    .common-box{
        padding: 20px 15px; 
        height: 100%;
        /* box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -ms-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25); */
        background-color: #F68C3D;
        color: #fff;
        border-radius: 8px;
        h2{
            font-size:14px;
            &.big{
                font-size:16px;
                margin-bottom: 15px;
            }
        }
        .number{
            font-size:24px;
            font-weight: 500;
            margin-bottom: 10px;
        }

    }
    .big-box{
        /* box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -ms-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 15px 70px -8px rgba(0, 0, 0, 0.25); */
        background-color: #fff;
        color: #000;
        border-radius: 8px;
        h2{
            font-size:16px;
            background-color: rgb(246, 140, 61);
            color: #fff;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;
        }

    }
    
   
    .box-list{
        display: flex;
        justify-content: space-between;
        padding: 8px 15px; 
        flex-wrap: wrap;
        .left{
            width: calc(100% - 40%);
        }
        .left,.right{
            font-family: Inter, sans-serif;
            font-weight: 500;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.rangePaper{
    position:relative;
    padding-bottom:40px;
    .rangeBtn{
        position:absolute;
        bottom: 0;
        left: 0;
    }
}


`;

export default Common