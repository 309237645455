import { createGlobalStyle } from "styled-components";

export const CommonTab = createGlobalStyle`

    .ant-tabs {
        .ant-tabs-nav-wrap{
            &:before,&:after{
                display:none;
            }
        }
        
        .ant-tabs-tab{
            .ant-tabs-tab-btn{
                color:#000;
                font-family: Inter, sans-serif;
                font-weight: 600;
                &:focus{
                    color:rgba(226, 106, 16,1);
                }
            }
            &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{
                    color:rgba(226, 106, 16,1);
                }
            }
            
        }
        .ant-tabs-ink-bar{
            background:rgba(226, 106, 16,1);
        }
        
    }

`;