import { createSlice } from "@reduxjs/toolkit";
import { API_UPDATE_CMS, API_GET_CMS_DETAIL, API_GET_LIST_EASTER_LINK, API_ADD_EASTER_LINK, API_UPDATE_EASTER_LINK, API_DELETE_EASTER_LINK, API_GET_LIST_TUTORIAL_LINK, API_ADD_TUTORIAL_LINK, API_UPDATE_TUTORIAL_LINK, API_DELETE_TUTORIAL_LINK } from "../constants";

const initialState = {
    cmsDetails: {},
    status: "",
    error: null,
    linkList: { data: [] },
    linkDetail: {},
    tutorialLink: { data: [] },
    tutorialLinkDetail: {}
};


export const cmsGetAction = (data) => ({
    type: "API",
    payload: {
        url: API_GET_CMS_DETAIL(data),
        method: "GET",
        // data: data,
        hideLoader: false,
        success: (data) => ({
            type: "cms/successDetails",
            payload: data,
        }),
        error: (data) => ({
            type: "cms/failDetails",
            payload: {},
        }),
    },
});

export const cmsEditAction = (data) => ({
    type: "API",
    payload: {
        url: API_UPDATE_CMS,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: "cms/successEdit",
            payload: data,
        }),
        error: (data) => ({
            type: "cms/failEdit",
            payload: {},
        }),
    },
});


//Get Easter Link List Action
export const getEasterLinkList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_LIST_EASTER_LINK,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getLinkList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getLinkList/fail',
            payload: {},
        }),
    },
})
//ADD EASTER LINK ACTION

export const addEasterLink = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_EASTER_LINK,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addLink/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addLink/fail',
            payload: {},
        }),
    },
})

//Ester Link Edit Action
export const editEasterLink = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_EASTER_LINK,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'linkEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'linkEdit/fail',
            payload: {},
        }),
    },
})

//User Delete Action
export const deleteEasterLink = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_EASTER_LINK,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteLink/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteLink/fail',
            payload: {},
        }),
    }
})


export const getTutorialLinkList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_LIST_TUTORIAL_LINK,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getTutorialLinkList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getTutorialLinkList/fail',
            payload: {},
        }),
    },
})
//ADD tutorial LINK ACTION

export const addTutorialLink = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_TUTORIAL_LINK,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addTutorialLink/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addTutorialLink/fail',
            payload: {},
        }),
    },
})

//tutorial Link Edit Action
export const editTutorialLink = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_TUTORIAL_LINK,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'tutorialLinkEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'tutorialLinkEdit/fail',
            payload: {},
        }),
    },
})

//tutorial Delete Action
export const deleteTutorialLink = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_TUTORIAL_LINK,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteTutorialLink/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteTutorialLink/fail',
            payload: {},
        }),
    }
})

// Reducer
const CmsSlice = createSlice({
    name: "cms",
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload;
        },
        successDetails: (state, action) => {
            state.cmsDetails = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('getLinkList/success', (state, action) => {
            state.linkList = action.payload
        })
        builder.addCase('getLinkList/fail', (state, action) => {
            state.linkList = { data: [] }
            state.isLoggedIn = false
        })
        builder.addCase('addLink/success', (state, action) => {
            state.linkList = action.payload.data
        })
        builder.addCase('addLink/fail', (state, action) => {
            state.linkList = { data: [] }
            state.isLoggedIn = false
        })
        builder.addCase('linkEdit/success', (state, action) => {
            state.linkDetail = action.payload.data
        })
        builder.addCase('linkEdit/fail', (state, action) => {
            state.linkDetail = {}
            state.isLoggedIn = false
        })

        builder.addCase('getTutorialLinkList/success', (state, action) => {
            state.tutorialLink = action.payload
        })
        builder.addCase('getTutorialLinkList/fail', (state, action) => {
            state.tutorialLink = { data: [] }
            state.isLoggedIn = false
        })
        builder.addCase('addTutorialLink/success', (state, action) => {
            state.tutorialLink = action.payload.data
        })
        builder.addCase('addTutorialLink/fail', (state, action) => {
            state.tutorialLink = { data: [] }
            state.isLoggedIn = false
        })
        builder.addCase('tutorialLinkEdit/success', (state, action) => {
            state.tutorialLinkDetail = action.payload.data
        })
        builder.addCase('tutorialLinkEdit/fail', (state, action) => {
            state.tutorialLinkDetail = {}
            state.isLoggedIn = false
        })
    },
});

export const { loaderChange } = CmsSlice.actions;
export default CmsSlice.reducer;
