import { createSlice } from '@reduxjs/toolkit';
import { API_ACTIVE_INACTIVE_OWNER, API_ADD_GLOBAL_CREDITS, API_ADD_VENUEOWNER, API_ALL_VENUEOWNER, API_CHACK_ADS_STATUS, API_CHANGE_ADS_STATUS, API_DELETE_RESTAURANT, API_DELETE_VENUEOWNER, API_GET_REPORTED_USER_LIST, API_GET_VENUE_ANALYTICS, API_GOAT_HISTORY, API_LIST_RESTAURANTS, API_UPDATE_RESTAURANTS, API_UPDATE_VENUEOWNER, GET_DETAIL_ADMIN_RESTAURANT, GET_VENUE_OWNER, UPDATE_GOAT_LEVEL } from '../constants';

const initialState = {
    isLoading: false,
    venueDetail: {},
    venueOwnersList: { data: [], recordsTotal: 0 },
    restaurantList: { data: [], recordsTotal: 0 },
    restaurantDetail: {},
    venueAnalytics: {},
    updateGoatLevel: {},
    userReportedList: { data: [], recordsTotal: 0 },
    venueGoatList: { data: [], recordsTotal: 0 },
    updateAddvertiseCredit: {},
    adsStatusDetails: {},
    skip: 0,
    limit: 10,
}
export const ADDVenueOwner = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_VENUEOWNER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addVenue/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addVenue/fail',
            payload: {},
        }),
    },
})
export const getVenueAnalytics = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_VENUE_ANALYTICS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'venueAnalytics/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'venueAnalytics/fail',
            payload: {},
        }),
    },
})

export const ownerActiveInactiveAction = (data) => ({
    type: 'API',
    payload: {
        url: API_ACTIVE_INACTIVE_OWNER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'activeInactive/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'activeInactive/fail',
            payload: {},
        }),
    }
})
export const AllVENUEOWNER = (data) => ({
    type: 'API',
    payload: {
        url: API_ALL_VENUEOWNER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'allVenue/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'allVenue/fail',
            payload: {},
        }),
    },
})

export const UpdateVenueOwner = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_VENUEOWNER,
        method: 'PATCH',
        data: data,
        hideLoader: false
    },
})


export const DeleteVenueOwner = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_VENUEOWNER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteVenueOwner/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteVenueOwner/fail',
            payload: {},
        }),
    },
})

export const ViewRestaurantLIst = (data) => ({
    type: 'API',
    payload: {
        url: API_LIST_RESTAURANTS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'viewRestaurants/success',
            payload: data,
        }),
    },
})
export const getVenueOwner = (data) => ({
    type: 'API',
    payload: {
        url: GET_VENUE_OWNER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getVenueOwner/success',
            payload: data,
        }),
    },
})
export const UPDATERestaurant = (data) => ({
    type: 'API',
    payload: {
        url: API_UPDATE_RESTAURANTS,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateRestaurant/success',
            payload: data,
        }),
    },
})

export const DELETERestaurant = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_RESTAURANT,
        method: 'DELETE',
        data: data,
        hideLoader: false,
    },
})
export const GetReportedUserDetail = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_REPORTED_USER_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'reportedUserDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'reportedUserDetail/fail',
            payload: {},
        }),
    },
})

export const GetRestaurantDetail = (data) => ({
    type: 'API',
    payload: {
        url: GET_DETAIL_ADMIN_RESTAURANT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'resDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'resDetail/fail',
            payload: {},
        }),
    },
})
export const updateGoatLevel = (data) => ({
    type: 'API',
    payload: {
        url: UPDATE_GOAT_LEVEL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateGoatLevel/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'updateGoatLevel/fail',
            payload: {},
        }),
    },
})
export const updateAddvertiseCredit = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_GLOBAL_CREDITS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateAddvertiseCredit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'updateAddvertiseCredit/fail',
            payload: {},
        }),
    },
})
export const getGoatList = (data) => ({
    type: 'API',
    payload: {
        url: API_GOAT_HISTORY,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getGoatListVenue/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getGoatListVenue/fail',
            payload: {},
        }),
    },
})


export const adsActiveInactiveAction = (data) => ({
    type: 'API',
    payload: {
        url: API_CHANGE_ADS_STATUS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'activeInactiveAds/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'activeInactiveAds/fail',
            payload: {},
        }),
    }
})
export const adsActiveInactiveActionGet = (data) => ({
    type: 'API',
    payload: {
        url: API_CHACK_ADS_STATUS,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'activeInactiveAdsGet/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'activeInactiveAdsGet/fail',
            payload: {},
        }),
    }
})


const VenueProfileSlice = createSlice({
    name: 'venueProfile',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('addVenue/success', (state, action) => {
            state.userList = action.payload.data
        })
        builder.addCase('getVenueOwner/success', (state, action) => {
            state.venueDetail = action.payload.data
        })
        builder.addCase('allVenue/success', (state, action) => {
            state.venueOwnersList.recordsTotal = action.payload.data.recordsTotal
            state.venueOwnersList.data = action.payload.data.venueOwnersList
        })
        builder.addCase('updateVenue/success', (state, action) => {
            state.venueDetail = action.payload.data[0].venueOwnersList
        })
        builder.addCase('viewRestaurants/success', (state, action) => {
            state.restaurantList = action.payload.data
        })
        builder.addCase('resDetail/success', (state, action) => {
            state.restaurantDetail = action.payload.data
        })
        builder.addCase('resDetail/fail', (state, action) => {
            state.restaurantDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('venueAnalytics/success', (state, action) => {
            state.venueAnalytics = action.payload.data
        })
        builder.addCase('venueAnalytics/fail', (state, action) => {
            state.venueAnalytics = {}
            state.isLoggedIn = false
        })
        builder.addCase('updateGoatLevel/success', (state, action) => {
            state.updateGoatLevel = action.payload.data
        })
        builder.addCase('updateGoatLevel/fail', (state, action) => {
            state.updateGoatLevel = {}
            state.isLoggedIn = false
        })
        builder.addCase('reportedUserDetail/success', (state, action) => {
            state.userReportedList = action.payload.data
        })
        builder.addCase('reportedUserDetail/fail', (state, action) => {
            state.userReportedList = {}
            state.isLoggedIn = false
        })
        builder.addCase('getGoatListVenue/success', (state, action) => {
            state.venueGoatList = action.payload.data
        })
        builder.addCase('getGoatListVenue/fail', (state, action) => {
            state.venueGoatList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('updateAddvertiseCredit/success', (state, action) => {
            state.updateAddvertiseCredit = action.payload.data
        })
        builder.addCase('updateAddvertiseCredit/fail', (state, action) => {
            state.updateAddvertiseCredit = {}
            state.isLoggedIn = false
        })
        builder.addCase('activeInactiveAdsGet/success', (state, action) => {
            state.adsStatusDetails = action.payload.data
        })
        builder.addCase('activeInactiveAdsGet/fail', (state, action) => {
            state.adsStatusDetails = {}
            state.isLoggedIn = false
        })
    }

})


// export const { loaderChange } = VenueProfileSlice.actions
export default VenueProfileSlice.reducer
