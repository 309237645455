import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from './loaderCss';
import { Spin } from 'antd';

const Loading = ({ isSuspense, children }) => {
    // const isLoading = useSelector(s => s?.api?.loading);
    // console.log("loding",isLoading)

    const isLoading = useSelector((state) => state?.auth?.isLoading);

    return (
        <>
            <Wrapper>
                {/* {(isSuspense || isLoading) && <div className="spinner-wrap" >
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>} */}
                {(isSuspense || isLoading) && <div className="spinner-wrap">
                    <Spin spinning={isSuspense} size='large' className="spinner" tip="Loading...">
                        <div className="content" />
                    </Spin>
                </div>
                }
                {children}

            </Wrapper>
        </>
    );
}

export default Loading;