import { createSlice } from '@reduxjs/toolkit'
import { API_SENT_NOTIFICATION, API_ADD_NOTIFICATION, API_GET_NOTIFICATION_DETAIL, API_GET_NOTIFICATION_LIST, API_NOTIFICATION_ANALYTICS, API_NOTIFICATION_DELETE, API_NOTIFICATION_EDIT } from '../constants/index'


// const projectName = "GOAT-Grub";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    notificationDetail: {},
    sidebarShow: true,
    notificationList: { data: [], recordsTotal: 0 },
    sentNotificationList: { data: [], recordsTotal: 0 },
    notificationAnalytics: {},
    start: 0,
    length: 10,
}

//Get notification List Action
export const getNotificationList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_NOTIFICATION_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getNotificationList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getNotificationList/fail',
            payload: {},
        }),
    },
})

export const getSentNotificationList = (data) => ({
    type: 'API',
    payload: {
        url: API_SENT_NOTIFICATION,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getSentNotificationList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getSentNotificationList/fail',
            payload: {},
        }),
    },
})

export const addNotification = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_NOTIFICATION,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addNotification/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addNotification/fail',
            payload: {},
        }),
    },
})

//Get notification Detail Action
export const getNotificationDetail = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_NOTIFICATION_DETAIL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getNotificationDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getNotificationDetail/fail',
            payload: {},
        }),
    },
})

//notification Edit Action
export const notificationEditAction = (data) => ({
    type: 'API',
    payload: {
        url: API_NOTIFICATION_EDIT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'notificationEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'notificationEdit/fail',
            payload: {},
        }),
    },
})

//notification Delete Action
export const notificationDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_NOTIFICATION_DELETE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteNotification/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteNotification/fail',
            payload: {},
        }),
    }
})

export const getNotificationAnalytics = (data) => ({
    type: 'API',
    payload: {
        url: API_NOTIFICATION_ANALYTICS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'notificationAnalytics/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'notificationAnalytics/fail',
            payload: {},
        }),
    },
})

// Reducer
const getNotificationSlice = createSlice({
    name: 'getNotificationList',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getNotificationList/success', (state, action) => {
            state.notificationList = action.payload.data
        })
        builder.addCase('getNotificationList/fail', (state, action) => {
            state.notificationList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getSentNotificationList/success', (state, action) => {
            state.sentNotificationList = action.payload.data
        })
        builder.addCase('getSentNotificationList/fail', (state, action) => {
            state.sentNotificationList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('addNotification/success', (state, action) => {
            state.notificationList = action.payload.data
        })
        builder.addCase('addNotification/fail', (state, action) => {
            state.notificationList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getNotificationDetail/success', (state, action) => {
            state.notificationDetail = action.payload.data
        })
        builder.addCase('getNotificationDetail/fail', (state, action) => {
            state.notificationDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('notificationEdit/success', (state, action) => {
            state.notificationDetail = action.payload.data
        })
        builder.addCase('notificationEdit/fail', (state, action) => {
            state.notificationDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('notificationAnalytics/success', (state, action) => {
            state.notificationAnalytics = action.payload.data
        })
        builder.addCase('notificationAnalytics/fail', (state, action) => {
            state.notificationAnalytics = {}
            state.isLoggedIn = false
        })

    },
})

export const { loaderChange } = getNotificationSlice.actions
export default getNotificationSlice.reducer
